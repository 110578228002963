<script setup lang="ts">
import modules from '@/definitions/moduleDefinitions'
import { useAccountApi } from '@/composables/api/accountApi'

const props = defineProps({
  currentModule: {
    type: String,
    default: '',
  },
})
const { t } = useI18n()
const { isRouteAllowed } = useHierarchicalPermissions()

const { fetchAccountData } = useAccountApi()

const { data: userData, isFetching } = useQuery({
  queryKey: ['fetchAccountData'],
  queryFn: async () => {
    return await fetchAccountData()
  },
  select: ({ data }) => data.user,
})

function goTo(navItem: Partial<NavigationItemI>) {
  if (!userData.value?.abilities) {
    return
  }

  if (navItem.group === 'dashboard') {
    return '/secure'
  }

  return navItem.to ? `/secure/${navItem.to}` : '/secure'
}

const isDev = import.meta.dev
</script>

<template>
  <ClientOnly>
    <div>
      <!-- Static sidebar for desktop -->
      <div class="hidden md:flex md:shrink-0">
        <div
          class="bg-primary-800 dark:bg-gray-900 shadow-lg md:fixed md:top-0 md:left-0 md:w-16 md:h-screen md:py-6 md:shrink-0 md:flex md:flex-col md:items-center md:text-white md:z-20"
        >
          <div class="flex items-center justify-center bg-primary-800 py-2 mt-2 mb-6">
            <layout-logo class="h-12 w-auto fill-white" />
          </div>
          <div class="flex flex-1 flex-col ">
            <div
              v-for="item in modules"
              :key="item.name"
              class="block text-xl"
            >
              <!-- <NuxtLink v-if="!isFetching && item.group && ability.can('read', item.group)" :to="goTo(item)" class="text-slate-50" :class="{ 'text-yellow-500': item.group === props.currentModule }"> -->
              <NuxtLink
                v-if="!isFetching && item.group && isRouteAllowed(item.to || item.group)"
                :to="goTo(item)"
                class="text-slate-50"
              >
                <layout-side-button
                  :icon="item.icon"
                  :tooltip="item.name"
                  class="hover:bg-primary"
                  :selected="item.group === props.currentModule"
                />
              </NuxtLink>
            </div>
            <div class="text-slate-50 mt-auto flex flex-col gap-1 mb-3">
              <NuxtLink
                href="https://docs.avantpage.app"
                target="_blank"
              >
                <layout-side-button
                  icon="heroicons:question-mark-circle"
                  tooltip="Help"
                  class="hover:bg-primary-dark"
                />
              </NuxtLink>
              <NuxtLink
                to="/secure/account/profile"
              >
                <layout-side-button
                  :tooltip="$t('pages.account.title')"
                  aria-haspopup="true"
                  aria-controls="overlay_menu"
                  class="bg-slate-300! h-12 w-12 hover:rounded-xl text-center text-2xl rounded-3xl"
                >
                  <account-profile-picture
                    :picture="userData?.picture"
                    :name="`${userData?.contact?.name} ${userData?.contact?.lastName}`"
                    size="small"
                    :is-loading="isFetching"
                  />
                </layout-side-button>
              </NuxtLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ClientOnly>
</template>

<style scoped>

</style>
