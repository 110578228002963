<script setup lang="ts">
import { useUtilsApi } from '@/composables/api/utilsApi'
import type { QuoteI } from '@/types/quotes'
import getTagSeverityLabel from '@/helpers/getTagSeverityLabel'
import toUSdateFormat from '@/helpers/toUSdateFormat'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:modelValue', 'hide', 'save'])
const dialogVisible = useVModel(props, 'modelValue', emit)

const { t } = useI18n()
const {
  globalSearch,
} = useUtilsApi()
const router = useRouter()

const params = ref({
  page: 1,
  per_page: 9,
  search: '',
})

const filters = [{ label: t('shared.categories.all'), value: 'all' }, { label: t('shared.categories.projects'), value: 'projects' }, { label: t('shared.categories.quotes'), value: 'quotes' }, { label: t('shared.categories.invoices'), value: 'invoices' }]
const currentFilter = ref('all')

const { data, isFetching } = useQuery({
  queryKey: ['fetchGlobalSearch', params],
  queryFn: async () => {
    if (!params.value.search)
      return []
    return await globalSearch(params.value)
  },
  select: ({ data }) => data ?? {},
})

const formattedData = computed(() => {
  if (!data.value)
    return []
  // Ask to improve this object in the backend to return just an array with the type inside each object
  if (currentFilter.value !== 'all')
    return data.value?.[currentFilter.value]?.map((item: QuoteI) => ({ ...item, type: currentFilter.value })) ?? []

  return Object.keys(data.value).reduce((acc, current) => {
    const currentGroup = data.value[current]
    if (!currentGroup || !currentGroup.length)
      return acc

    acc.push(...currentGroup.slice(0, 3).map(item => ({ ...item, type: current })))
    return acc
  }, [])
})

function highlight(text: string) {
  if (!text)
    return text

  const query = new RegExp(params.value.search, 'gi')
  return text
    .toString()
    .replace(query, (matchedTxt, a, b) => {
      return (
        `<span class='bg-primary-200/40'>${
          matchedTxt
        }</span>`
      )
    })
}

function selectItem(item: GenericObjectI) {
  const module = item?.type ?? ''
  if (!module)
    return
  if (module === 'invoices') {
    router.push(`/secure/${module}/history?invoice=${item?.idNumber}`)
  }
  else {
    router.push({
      path: `/secure/${module}/${item?.id}`,
    })
  }
  emit('hide')
}
</script>

<template>
  <pv-dialog
    v-model:visible="dialogVisible"
    :modal="true"
    :close-on-escape="true"
    :dismissable-mask="true"
    :pt="{
      header: 'p-0! rounded-t-lg border-b',
      content: 'p-0!',
      headerActions: 'hidden',
    }"
    :style="{ width: '50rem' }"
    :pt-options="{ mergeProps: true }"
  >
    <template #header>
      <div class="w-full flex flex-col  items-center">
        <FormKit
          v-model="params.search"
          type="search"
          inner-class="text-xl bg-slate-100/90! border-0! rounded-b-none! max-w-full! min-h-[3.4rem]"
          outer-class="w-full"
          :autofocus="true"
          :placeholder="t('header.enter-query')"
          delay="300"
          @suffix-icon-click="emit('hide')"
        >
          <template #prefixIcon>
            <Icon
              name="ap:search-lg"
              class="w-6 mx-3 h-6 text-gray-500"
            />
          </template>
        </FormKit>
        <div class="px-4 py-2 flex gap-2 self-start mt-3 mb-4">
          <div
            v-for="item in filters"
            :key="item"
          >
            <pv-button
              v-if="item.value !== currentFilter"
              class="capitalize"
              severity="secondary"
              outlined
              :label="item.label"
              @click="currentFilter = item.value"
            />
            <pv-button
              v-else
              class="capitalize"
              :label="item.label"
            />
          </div>
        </div>
      </div>
    </template>
    <div
      v-if="!params.search && !isFetching"
      class="py-36 flex w-full flex-col items-center gap-3"
    >
      <img
        src="~assets/undraw/u_global_search.svg"
        alt="logo"
        class="w-[30%] py-5"
      >
      <span class="capitalize text-lg text-slate-500 font-bold mb-4">{{ t('header.start-typing') }}</span>
    </div>
    <ul
      role="list"
      class="divide-y divide-gray-100"
    >
      <template v-if="isFetching">
        <li
          v-for="item in 5"
          :key="item"
          class="relative flex justify-between items-center gap-x-6 px-3 py-3"
        >
          <pv-skeleton
            width="8rem"
            height="1.3rem"
          />
          <div class="flex flex-col gap-2 items-end">
            <pv-skeleton
              width="8rem"
              height="1rem"
            />
            <pv-skeleton
              width="4.5rem"
              height="1.5rem"
            />
          </div>
        </li>
      </template>
      <template v-else-if="formattedData.length">
        <li
          v-for="item in formattedData"
          :key="item"
          class="relative cursor-pointer flex justify-between items-center gap-x-6 px-5 py-3 hover:bg-slate-100/60"
          @click="selectItem(item)"
        >
          <div class="flex min-w-0 gap-x-4">
            <div class="flex flex-col items-start gap-2">
              <div
                class="text-md font-semibold leading-6 text-gray-600"
                v-html="highlight(item.idNumber)"
              />
              <div
                v-if="item.name"
                class="text-sm text-slate-600"
              >
                {{ item.name }}
              </div>
              <span
                v-else-if="item.totalNetto"
                class="text-sm text-slate-600"
              >{{ t('invoices.amount') }} : ${{ item.totalNetto }}</span>
            </div>
          </div>
          <div class="flex shrink-0 items-center gap-x-4">
            <div class="flex flex-col items-end gap-2">
              <p
                v-if="item.startDate"
                class="text-sm text-slate-500"
              >
                {{ t('projects.start-date') }} : {{ toUSdateFormat (item.startDate) }}
              </p>
              <p
                v-else-if="item.dueDate"
                class="text-sm text-slate-500"
              >
                {{ t('shared.form.deadline') }}  : {{ toUSdateFormat (item.dueDate) }}
              </p>
              <pv-tag
                :severity="getTagSeverityLabel(item.status).severity"
                :value="getTagSeverityLabel(item.status).label"
              />
            </div>
          </div>
        </li>
      </template>
      <div
        v-else-if="params.search "
        class="flex my-52 flex-col gap-3 items-center"
      >
        <span class="text-xl font-bold">{{ t('notify.no-items') }}</span>
        <span class=" max-w-sm text-md text-center text-slate-500 font-bold">{{ t('header.search-another') }}</span>
      </div>
    </ul>
  </pv-dialog>
</template>
